export default {
  environment: 'development',
  apiKey: 'AIzaSyD7k1_au2o4dGm1YkPXUv0UrLdX1s3mIoY',
  authDomain: 'hiber-web-dev.firebaseapp.com',
  databaseURL: 'https://hiber-web-dev.firebaseio.com',
  projectId: 'hiber-web-dev',
  storageBucket: 'hiber-web-dev.appspot.com',
  messagingSenderId: '250838593774',
  host: {
    customer: 'https://web.api.dev.env.hiber.cloud',
    support: 'https://web.support-api.dev.env.hiber.cloud',
  },
  mapsApiKey: 'AIzaSyD_lfdObUU5tuslWPJbOMZ6exLpGdsr-BA',
  sentryUrl: 'https://839e94954c43ba8c94fe0a963fe2851b@o4505946915209216.ingest.sentry.io/4506065969348608',
  hiberCloudUrl: 'https://dev.env.hiber.cloud',
};
